import { Button, Grid, Typography } from '@mui/material';
import React from 'react';

export type CustomNoRowsOverlayProps = {
    onAddNewItem?: () => void;
    onUserTenantSection?: boolean;
    message?: string;
    btnText?: string;
    customBtn?: React.ReactNode;
};

export const CustomNoRowsOverlay = ({ onAddNewItem, message, onUserTenantSection, btnText, customBtn }: CustomNoRowsOverlayProps) => (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
        <Grid item container justifyContent="center" xs="auto" sx={{ maxWidth: '200px !important', width: '100%' }}>
            <Grid item xs={12} sx={{ mb: '-5px' }}>
                <svg style={{ width: '100%' }} width="140" height="88" viewBox="0 0 140 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M70 87.3468C108.66 87.3468 140 80.5031 140 72.0611C140 63.619 108.66 56.7754 70 56.7754C31.3401 56.7754 0 63.619 0 72.0611C0 80.5031 31.3401 87.3468 70 87.3468Z"
                        fill="#D9D9D9"
                    />
                    <path
                        d="M120.312 30.0638L98.1181 4.94725C97.0528 3.23525 95.4975 2.2002 93.8591 2.2002H46.1409C44.5025 2.2002 42.9472 3.23525 41.8819 4.94507L19.6875 30.066V50.2409H120.312V30.0638Z"
                        stroke="#D9D9D9"
                    />
                    <path
                        d="M91.0284 36.9882C91.0284 33.4834 93.2028 30.5901 95.9 30.5879H120.312V70.1931C120.312 74.829 117.425 78.6286 113.859 78.6286H26.1406C22.575 78.6286 19.6875 74.8268 19.6875 70.1931V30.5879H44.1C46.7972 30.5879 48.9716 33.4769 48.9716 36.9817V37.0297C48.9716 40.5345 51.17 43.3645 53.865 43.3645H86.135C88.83 43.3645 91.0284 40.5083 91.0284 37.0035V36.9882Z"
                        fill="#F5F6F7"
                        stroke="#D9D9D9"
                    />
                </svg>
            </Grid>
            {!onUserTenantSection && (
                <Grid item xs={12}>
                    <Typography variant="body1" fontSize="20px" color="#54595E" sx={{ textAlign: 'center !important' }}>
                        {message || 'No items found'}
                    </Typography>
                </Grid>
            )}
            {customBtn || null}
            {onAddNewItem && !customBtn && (
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={onAddNewItem} sx={{ mt: '12px' }} fullWidth>
                        {btnText || 'Add Line Item'}
                    </Button>
                </Grid>
            )}
        </Grid>
    </Grid>
);
