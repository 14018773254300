import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Edit, Save, Close } from '@mui/icons-material';
import {
    GridRowModesModel,
    GridRowModes,
    DataGridPro,
    GridColumns,
    GridRowParams,
    MuiEvent,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    DataGridProProps,
    GridSlotsComponent,
    GridRowOrderChangeParams,
    useGridApiRef,
    GridActionsColDef
} from '@mui/x-data-grid-pro';
import { reorder } from 'views/backoffice/recordsAditionalFields/views/LineItems/utils';
import { getOnlyUpdatedRows, orderAlphabetical, randomInteger } from './utils';
import { GridToolbar, GridToolbarProps } from './components';
import { ListObject } from 'views/backoffice/CustomLists/types';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

export type GridWithInlineEditProps = {
    GridComponent?: (props: DataGridProProps) => JSX.Element | null;
    loading: boolean;
    initialRows: readonly Record<string, any>[];
    columns: GridColumns;
    fieldToFocus?: string;
    onUpdate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    onCreate: (newRow: Record<string, any>) => Promise<Record<string, any> | undefined>;
    disabledReordering?: boolean;
    onUpdateOrder?: (newOrder: Record<string, any>[]) => Promise<boolean>;
    gridComponents?: Partial<GridSlotsComponent>;
    requiredFields?: string[];
    selectedRow?: ListObject;
    handleOpenFormObjectList?: (rowData: { [key: string]: any }, dynamicObjectId: string) => void;
    handleShowImportDialog?: () => void;
    isPreview?: boolean;
    initialState?: GridInitialStatePro;
    actionColDefProps?: Partial<GridActionsColDef>;
};

export type GridWithInlineEditRef = {
    handleAddClick: (initialValue?: Record<string, any>) => void;
    handleQuickSearch: (search: string) => void;
};

/**
 * Grid with Inline Edit
 *
 * This component is used to render a grid with inline edit functionality
 * exposing the add new row functionality to the parent component
 *
 */
const GridWithInlineEdit = forwardRef(
    (
        {
            GridComponent = DataGridPro,
            loading,
            initialRows,
            columns,
            onUpdate,
            onCreate,
            disabledReordering,
            onUpdateOrder,
            gridComponents,
            fieldToFocus = 'value',
            requiredFields = [],
            selectedRow,
            handleOpenFormObjectList = (rowData: { [key: string]: any }, dynamicObjectId: string) => {},
            handleShowImportDialog,
            isPreview = false,
            initialState,
            actionColDefProps
        }: GridWithInlineEditProps,
        ref
    ) => {
        const apiRef = useGridApiRef();
        const [isReordering, setIsReordering] = useState(false);
        const [isSubmittingOrder, setIsSubmittingOrder] = useState(false);
        const [rows, setRows] = useState<GridRowModel[]>([...initialRows].sort((a, b) => a.order - b.order));
        const [reorderedRows, setReorderedRows] = useState([...initialRows].sort((a, b) => a.order - b.order));
        const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

        const handleEditClick = (id: GridRowId, row: GridRowModel) => () => {
            if (selectedRow?.objectDefinition?.id) {
                const { dynamicObjectValue } = row;
                const { objectValues } = dynamicObjectValue;
                const rowData: { [key: string]: any } = {};
                objectValues.forEach((val: any) => {
                    rowData[val.objectProperty.name] = val.value;
                });
                handleOpenFormObjectList({ rowData, rowId: row.id }, dynamicObjectValue.id);
            } else {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
            }
        };

        const handleSaveClick = (id: GridRowId) => () => {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        };

        const handleCancelClick = (id: GridRowId) => () => {
            setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.View, ignoreModifications: true }
            });

            const editedRow = rows.find((row) => row.id === id);
            if (editedRow?.isNew) {
                setRows(rows.filter((row) => row.id !== id));
            }
        };

        const handleAddClick = (initialValue?: Record<string, any>) => {
            const id = randomInteger();
            // The use here of columns could be a problem if the columns are not defined properly
            const editableFields =
                initialValue ||
                columns
                    .filter((column) => column.editable)
                    // eslint-disable-next-line no-nested-ternary
                    .reduce((acc, key) => ({ ...acc, [key.field]: key.type === 'boolean' ? false : key.type === 'number' ? 0 : '' }), {});

            setRows((oldRows) => [{ id, isNew: true, ...editableFields }, ...oldRows]);
            setRowModesModel((oldModel) => ({
                [id]: { mode: GridRowModes.Edit, fieldToFocus },
                ...oldModel
            }));
        };

        const handleQuickSearch = (search: string) => {
            apiRef.current?.setQuickFilterValues([search]);
        };

        const processRowUpdate = async (newRow: GridRowModel) => {
            let updatedRow: Record<string, any> | undefined = { ...newRow };
            if (updatedRow.isNew) {
                updatedRow.isNew = false;
                updatedRow = await onCreate(updatedRow);
            } else {
                updatedRow = await onUpdate(updatedRow);
            }

            if (updatedRow) {
                setRows(rows.map((row) => (row.id === newRow.id ? (updatedRow as Record<string, any>) : row)));

                return updatedRow;
            }

            return newRow;
        };

        const processRowReorderingUpdate = async () => {
            const onlyUpdatedRows = getOnlyUpdatedRows(rows, reorderedRows);
            setRows(reorderedRows);
            setIsSubmittingOrder(true);

            if (onUpdateOrder) {
                const success = await onUpdateOrder(onlyUpdatedRows);
                if (success) setIsReordering(false);
                else setRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));
            }
            setIsSubmittingOrder(false);
        };

        const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
            event.defaultMuiPrevented = true;
        };

        const handleRowReorderChange = ({ targetIndex, oldIndex }: GridRowOrderChangeParams) => {
            const updatedOrder = reorder<Record<string, any>>(reorderedRows, oldIndex, targetIndex);
            setReorderedRows(updatedOrder.map((row, index) => ({ ...row, order: index + 1 })));
        };

        const handleRowAlphabeticalOrder = () => {
            const updatedOrder = orderAlphabetical(rows, fieldToFocus);
            setReorderedRows(updatedOrder);
            apiRef.current.setRows(updatedOrder);
        };

        const allColumns: GridColumns = [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                cellClassName: 'actions',
                getActions: ({ id, row }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        const actualState = isInEditMode ? apiRef.current.state.editRows[id] : false;

                        const isDisabled =
                            actualState && requiredFields.length
                                ? Object.keys(actualState).some((key) => requiredFields.includes(key) && !actualState[key].value)
                                : false;

                        return [
                            <GridActionsCellItem icon={<Save />} label="Save" disabled={isDisabled} onClick={handleSaveClick(id)} />,
                            <GridActionsCellItem
                                icon={<Close />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(id)}
                                color="inherit"
                            />
                        ];
                    }

                    return [
                        <GridActionsCellItem
                            icon={<Edit />}
                            label="Edit"
                            className="textPrimary"
                            onClick={!isPreview ? handleEditClick(id, row) : undefined}
                            color="inherit"
                        />
                    ];
                },
                ...actionColDefProps
            },
            ...columns
        ];

        useImperativeHandle(ref, () => ({
            handleAddClick,
            handleQuickSearch
        }));

        useEffect(() => {
            if (initialRows) {
                setRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));
                setReorderedRows([...initialRows].sort((a, b) => a.order - b.order).map((row, idx) => ({ ...row, order: idx + 1 })));
            }
        }, [initialRows]);

        return (
            <GridComponent
                apiRef={apiRef}
                loading={loading || isSubmittingOrder}
                rows={rows}
                columns={allColumns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                components={{ Toolbar: !isPreview ? GridToolbar : undefined }}
                componentsProps={{
                    toolbar: !isPreview
                        ? ({
                              ToolbarButtons: gridComponents?.Toolbar,
                              rowReorder: !disabledReordering,
                              onSaveReorder: processRowReorderingUpdate,
                              onAlphabeticalOrder: handleRowAlphabeticalOrder,
                              loadingReorder: isSubmittingOrder,
                              toggleReorder: () => {
                                  if (isReordering) apiRef.current.setRows(rows);
                                  setIsReordering(!isReordering);
                                  setReorderedRows(rows);
                              },
                              isReordering,
                              handleShowImportDialog
                          } as GridToolbarProps)
                        : undefined
                }}
                initialState={initialState}
                onProcessRowUpdateError={(error) => console.log('error', error)}
                experimentalFeatures={{ newEditingApi: true }}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                checkboxSelection
                disableSelectionOnClick
                pagination
                rowReordering={!disabledReordering && isReordering}
                onRowOrderChange={handleRowReorderChange}
            />
        );
    }
);

export default GridWithInlineEdit;
